<template>
  <div class="component-footer">
    <div class="footer-content">
      <div class="left">
        <div class="logo"><img src="../assets/logo.png" /> </div>
        <div class="link">
          <span>Idle Mystic:</span>
          <a target="_blank" href="https://twitter.com/Idle_Mystic"><img src="../assets/images/icon-1.png" /> </a>
          <a target="_blank" href="https://t.me/IdleMystic_official"><img src="../assets/images/icon-2.png" /> </a>
          <a target="_blank" href="https://medium.com/@IdleMysticOfficial"><img src="../assets/images/icon-3.png" /> </a>
          <a target="_blank" href="https://discord.com/invite/m94hd9b8cw"><img src="../assets/images/icon-4.png" /> </a>
        </div>
        <div class="copyright">© 2021 PTE Inc.All right reserved</div>
      </div>
      <div class="right">
        <div class="item">
          <div class="title">Product</div>
          <div class="li"><a href="https://idlemystic.io/">Idle Mystic</a></div>
          <div class="li"><router-link to="/">Ready World One</router-link> </div>
        </div>
        <div class="item">
          <div class="title">About PTEG</div>
          <div class="li"><router-link to="/about">About Us</router-link> </div>
          <div class="li"><router-link to="/news">News</router-link></div>
        </div>
        <div class="item">
          <div class="title">Contact Us</div>
          <div class="li">business@pteg.io</div>
          <div class="li">community@pteg.io</div>
          <div class="li">PTE (Hong Kong) Ltd<br />
            8/F, Tower 2, Admiralty Centre,<br />
            18 Harcourt Road, Admiralty, Hong Kong</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    openComingSoonn() {
      this.$store.commit('setComingSoon', true)
    }
  }
}
</script>

<style lang="less">
@import "../less/main.less";
.component-footer {
  width: 100%;
  border-top: solid 1px #3F3F3F;
  .footer-content {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    padding: 36px 0;
    color: #545454;
    @media @mobile {
      padding: 12px 0;
    }
    .left {
      width: 30%;
      @media @mobile {
        width: 100%;
      }
      .logo {
        padding-bottom:12px;
        @media @mobile {
          display: none;
        }
        img {
          width: 56%;
          @media @mobile {
            width: 30%;
            margin-left: 24px;
          }
        }
      }
      .link {
        display: flex;
        @media @mobile {
          justify-content: center;
        }
        span {
          color: #fff;
          display: flex;
          align-items: center;
          padding-right: 6px;
        }
        a {
          margin-right: 12px;
          height: 24px;
          img {
            width: 24px;
          }
          &:hover {
            img {
              opacity: 0.8;
            }
          }
        }
      }
      .copyright {
        color: #282828;
        line-height: 36px;
        @media @mobile {
          text-align: center;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      @media @mobile {
        display: none;
      }
      .item {
        flex: 1;
        &:last-of-type {
          flex: 2;
        }
        .title {
          font-size: 16px;
          color: #fff;
          padding-bottom: 12px;
          font-family: GothamBold;
        }
        .li {
          padding: 3px 0;
          a {
            color: #545454;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
  }

}
</style>
