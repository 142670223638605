import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import routes from './routes'
// import { local } from '../config/constant'

NProgress.configure({
  showSpinner: false
})

Vue.prototype.transitionName = ''
Vue.prototype.$routeBack = function() {
  Vue.prototype.transitionName = 'slide-right'
  this.$router.back()
}

Vue.prototype.$routePush = function(...args) {
  Vue.prototype.transitionName = 'slide-left'
  this.$router.push(...args)
}

Vue.prototype.$routeReplace = function(...args) {
  Vue.prototype.transitionName = 'slide-left'
  this.$router.replace(...args)
}

Vue.use(VueRouter)
const router = new VueRouter(routes)

router.beforeEach((to, from, next) => {
  // let token = localStorage.getItem(local.AUTH)
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
