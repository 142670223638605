import Vue from "vue"

let mobile = false
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    mobile = true
}

Vue.prototype.$mobile = mobile

const routes = {
    mode: 'history',
    routes: [
        { path: '/', component: () => import('../pages/home') },
        { path: '/products', component: () => import('../pages/home') },
        { path: '/about', component: () => import('../pages/about') },
        { path: '/jobs', component: () => import('../pages/jobs') },
        { path: '/privacy', component: () => import('../pages/privacy') },
        { path: '/news', component: () => import('../pages/news') },
        { path: '/news/:id', component: () => import('../pages/news/info') }
    ]
}

export default routes
