<template>
  <div class="component-header">
    <div class="header-content">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/logo.png" />
        </router-link>
      </div>
      <div class="header-right">
          <div class="nav-button" @click="active = true">
              <span></span>
              <span></span>
              <span></span>
          </div>
          <div class="nav-container" :class="{show: active}">
              <div class="nav-bg" @click="active = false"></div>
              <div class="nav">
                  <div class="nav-item">
                      <router-link to="/products">Products</router-link>
                  </div>
                  <div class="nav-item">
                      <router-link to="/about">About us</router-link>
                  </div>
                  <div class="nav-item">
                      <router-link to="/privacy">Privacy</router-link>
                  </div>
                  <div class="nav-item">
                      <router-link to="/jobs">Jobs</router-link>
                  </div>
                  <div class="nav-item">
                      <!--          <router-link to="/news">News</router-link>-->
                      <router-link to="/news">News</router-link>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
      return {
          active: false
      }
    },
    watch: {
      '$route.path'() {
          this.active = false
      }
    },
    methods: {
        gotoNews() {
            this.$store.commit('setComingSoon', true)
        }
    }
}
</script>

<style lang="less">
@import "../less/main.less";
.component-header {
    width: 100%;
    border-bottom: solid 1px #3F3F3F;
    .header-content {
        width: 960px;
        height: 48px;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media @mobile {
            width: 100%;
        }
    }
    .logo {
        display: flex;
        align-items: center;
        a {
            display: flex;
            align-items: center;
            height: 100%;

            img {
                height: 16px;
            }
        }
        @media @mobile {
            padding-left: 24px;
        }
    }
    .header-right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .nav-button {
        @media @mobile {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 28px;
            margin-right: 12px;
        }
        span {
            @media @mobile {
                width: 100%;
                background-color: #fff;
                height: 3px;
                margin: 3px 0;
                border-radius: 1.5px;
            }
        }
    }
    .nav-container {
        @media @mobile {
            position: fixed;
            z-index: 999;
            right: -50vw;
            top: 0;
            background-color: #000;
            width: 50vw;
            height: 100vh;
            box-shadow: -1px -1px 8px #444;
            transition: right .3s;
        }
        &.show {
            right: 0;
            .nav-bg {
                @media @mobile {
                    position: fixed;
                    z-index: 0;
                    width: 100vw;
                    height: 100vh;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    .nav {
        display: flex;
        padding-right: 36px;
        position: relative;
        z-index: 1;
        @media @mobile {
            padding-right: 0;
            flex-direction: column;
            padding-top: 36px;
        }
        .nav-item {
            padding-left: 36px;
            display: flex;
            @media @mobile {
                padding: 8px 0 8px 36px;
            }
            a {
                display: flex;
                position: relative;
                align-items: center;
                padding-top: 4px;
                font-size: 16px;
                font-family: GothamBold;
                &.router-link-active {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #008900;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
</style>
