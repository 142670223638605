<template>
  <div id="app">
    <page-header />
    <router-view></router-view>
    <page-footer />
    <div class="coming-soon" v-if="$store.state.comingSoon">
      <div class="coming-soon-bg" @click="closeComingSoon"></div>
      <div class="coming-soon-content">
        <div class="icon"><img src="data:image/webp;base64,UklGRroDAABXRUJQVlA4TK0DAAAvKkANEA4Itm03qZj/TD7F3lsU6RasA/ILCTiCm7yEzH9ArbYtsjMFZEFlU+goOrh0kJFxg8++6UsHl1QwdBBUFjW4SHCRzEl0zk8HrwO3kRTJOcY6eAXw/v7Ob3p/dQzA+94rP+r5FwBeL619/XvObXuVd17/Hzdf6Lrn7BhaJFr22J9pT+vHVPWYH7kvrSl176vu8z2yrWcrZU053GLLuqXyaK2uKjtrdUtlz/kMZVvGlH1L5WwMal2jr1NL16Kvc91lveRSAJyPl7smykpboGwuUZeh7vL+HHyWlNU5OPUNnMdz8HEezkHZ1JX1KbYYHQNOuy22BuenYk/Xj2L7fxynfC1SFIW8L2JO74WKoYhROLXGaJ68z0MGZZXvefKiBIqGKLsqZJc8ZLkfMNuzZAXI3iuVXQS3ZUsWlR+RtllUOvPXpH0aKMcAnQbpmSu9pEEyVZ6XtoLh6Z4GReMYVHJNA6nqU5IlDdL1MyJ5nwaCcyDtknTxAsRNsifpkq26PT3FJUmX7GkNisv3eaLiKY6JByBuojWOMdElOpGKF2NMtJYVlIxjovcNfrroGoXiDlDxQPWOPYDoHIWiEQ2Km3A/RoYdgC6c5LdwTGsA4fkYeVyBRofDHgbhN1b9D6DwfRiy3yGHJQzC1Tb847J6vx7S7bfDCQA6AKw63O7LId1hROMsG/bRel8Od6x2DBqs2Z/30Zrt7Ig2sWa3shCxyTEQdjMRsdGiDctud2UXKbc9dsF2kFIqdmp1ebdJKXcjaxwDJMDa3Sil3C3NL267bdnGbqbKqG0q3y5ZK9dt0+MmZr1vjqhkUGn9noxpdlh162haNahaB9VWzTp6XVZNA+t2FbXaOWtW4exWalXET7CLbRksj5wVy3AWLN4vg+U3ocWwiF1M4LDgNJ+tVQLzeh4z31nFMw930XyfZ1Ys32ychaYdeAi8pj2R5p9Po6ZX/mLF//amu5oUyzMZp1HTrjlowahxmWRyFXxlk/D1q0kwmTjGx3G68SjQgd9o6jpdNxxHMeOjwCgCo84YVTfYhhnsECCBfkU0HOsTBpH9SURCUH+Wg6Wm3/aD/vv62t4Nei/6t1r3o3Xv1LtBv6rrD/2gJuh76frtR782wV3v0gnrLcG5/65fAwi6yjAA8Mcgmvx3waYbZ4PbfuW1QXTwvsqb/ZBfqFXau/NDXl/lzl5+UY1XfsKdAbjPvZ7c7Se5v4mGz7w/8ne5P90/bn6X+6v3yP3y1/+qv1fuPQA=" /> </div>
        <div class="text">COMING SOON...</div>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from './components/header'
import pageFooter from './components/footer'
export default {
  name: 'App',
  components: {
    pageHeader,
    pageFooter
  },
  methods: {
    closeComingSoon() {
      this.$store.commit('setComingSoon', false)
    }
  }
}
</script>

<style lang="less">
@import './less/main.less';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .coming-soon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    .coming-soon-bg {
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
    }
    .coming-soon-content {
      position: absolute;
      background-color: #000;
      top: 35%;
      left: 50%;
      z-index: 1001;
      padding: 24px;
      width: 200px;
      height: 200px;
      border-radius: 9px;
      border: solid 1px #3F3F3F;
      margin-left: -100px;
      .icon {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        img {
          width: 24px;
        }
      }
      .text {
        text-align: center;
        font-size: 18px;
        padding-top: 24px;
      }
    }
  }
}
</style>
